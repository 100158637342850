import React from "react"
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo"
import { graphql } from "gatsby"
import componentGenerator from "@utils/componentGenerator"

function PageTemplate(props) {
  const components = props?.data?.allContentfulPage?.edges?.[0].node?.components
  const seo = props?.data?.allContentfulPage?.edges?.[0].node?.pageSeo
  const pageData = props?.data?.allContentfulPage?.edges?.[0].node
  const { slug } = props?.pageContext
  const simpleHeader =
    props?.data?.allContentfulPage?.edges?.[0].node?.simpleHeader
  const simpleHeaderCta =
    props?.data?.allContentfulPage?.edges?.[0].node?.simpleHeaderCta
  const simpleFooter =
    props?.data?.allContentfulPage?.edges?.[0].node?.simpleFooter
  const seoBreadcrumbs = seo?.breadcrumbs

  return (
    <Layout
      simpleHeader={simpleHeader}
      simpleHeaderCta={simpleHeaderCta}
      simpleFooter={simpleFooter}
    >
      <Seo
        title={seo?.seoTitle || pageData?.title}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        image={seo?.graphImage?.file?.url || pageData?.featuredImage?.file?.url}
        metaTags={seo?.metaTags}
        article={seo?.article}
      />
      <div style={{ marginTop: "100px" }}>
        {componentGenerator(slug, components, seoBreadcrumbs)}
      </div>
    </Layout>
  )
}

export default PageTemplate

export const PageQuery = graphql`
  query PageQuery($id: String) {
    allContentfulPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          __typename
          slug
          id
          simpleHeader
          simpleHeaderCta {
            id
            internalName
            internalLink
            label
            link
            variant
          }
          simpleFooter
          pageSeo {
            graphImage {
              description
              title
              file {
                url
              }
            }
            seoTitle
            seoDescription
            canonical
            noIndex
            metaTags
            article
            breadcrumbs {
              title
              url
              id
            }
          }
          components {
            ... on ContentfulComponentHeading {
              __typename
              id
              internalName
              backgroundColor
              kicker
              headingSize
              heading
              noTopPadding
              subheading {
                subheading
              }
              callsToAction {
                contentful_id
                id
                internalLink
                label
                type
                link
                variant
              }
            }
            ... on ContentfulComponentSlidebox {
              __typename
              id
              internalName
              noTopPadding
              noBottomPadding
              slideboxHeading: heading {
                id
                internalName
                backgroundColor
                kicker
                headingSize
                heading
                subheading {
                  subheading
                }
                callsToAction {
                  contentful_id
                  id
                  internalLink
                  label
                  type
                  link
                  variant
                }
              }
              slides {
                ... on ContentfulComponentGeneric {
                  id
                  align
                  background
                  navTitle
                  iconIdSelect
                  callsToAction {
                    variant
                    type
                    link
                    label
                    id
                    internalName
                    internalLink
                    contentful_id
                  }
                  kicker
                  heading
                  headingSize
                  subheading {
                    raw
                  }
                  bodyCopy {
                    raw
                  }
                  featuredImage {
                    gatsbyImageData
                    file {
                      url
                    }
                    title
                    description
                    contentful_id
                  }
                  lottie {
                    file {
                      url
                    }
                  }
                  video {
                    videoUrl
                    thumbnail {
                      gatsbyImageData
                      url
                      title
                      description
                    }
                    autoplay
                    videoFile {
                      title
                      description
                      file {
                        url
                      }
                    }
                  }
                }
              }
            }
            ... on ContentfulComponentConversionPanel {
              kicker
              __typename
              id
              textAlign
              alignContent
              background
              noTopPadding
              noBottomPadding
              subheading {
                raw
              }
              callsToAction {
                contentful_id
                id
                internalLink
                label
                type
                link
                variant
              }
              headingSize
              heading
              type
              reference {
                ... on ContentfulComponentTrustbar {
                  __typename
                  id
                  heading
                  companies {
                    id
                    name
                    logo {
                      gatsbyImageData
                      file {
                        url
                      }
                      title
                      description
                    }
                  }
                }
                ... on ContentfulComponentTestimonial {
                  __typename
                  id
                  author {
                    id
                    fullName
                    role
                    headshot {
                      gatsbyImageData
                      file {
                        url
                      }
                      title
                      description
                    }
                    company {
                      id
                      name
                    }
                  }
                  quote {
                    quote
                  }
                }
                ... on ContentfulComponentImage {
                  __typename
                  id
                  primaryImage {
                    file {
                      url
                    }
                    description
                  }
                }
              }
            }
            ... on ContentfulComponentTrustbar {
              __typename
              id
              companies {
                logo {
                  gatsbyImageData
                  description
                  file {
                    url
                  }
                  title
                  description
                }
              }
              noTopPadding
              noBottomPadding
              background
              heading
              id
              type
              internalName
              contentful_id
            }
            ... on ContentfulComponentHero {
              __typename
              id
              background
              videoUrl
              type
              alignImage
              mobileTextAlign
              subheading {
                raw
              }
              kicker
              heading
              categories {
                title
                backgroundColor
                fontColor
              }
              headingSize
              breadcrumbs
              breadcrumbTitle
              callsToAction {
                contentful_id
                id
                internalLink
                label
                type
                link
                variant
              }
              featuredImage {
                gatsbyImageData
                file {
                  url
                  contentType
                }
                title
                description
              }
              mobileBackgroundImage {
                gatsbyImageData
                file {
                  url
                }
                title
                description
              }
              reference {
                ... on ContentfulComponentForm {
                  __typename
                  id
                  anchorLinkId
                  internalName
                  marketoFormId
                  background
                  javascriptSnippet {
                    javascriptSnippet
                  }
                }
                ... on ContentfulComponentImage {
                  __typename
                  id
                  primaryImage {
                    gatsbyImageData
                    file {
                      contentType
                      url
                    }
                    title
                    description
                  }
                }
              }
              videoThumbnail {
                gatsbyImageData
                file {
                  url
                }
                title
                description
              }
            }
            ... on ContentfulComponentSwitchback {
              __typename
              id
              type
              noTopPadding
              noBottomPadding
              alignImage
              animation1Kicker
              animation1Heading
              animation1 {
                raw
              }
              animation2Kicker
              animation2Heading
              animation2 {
                raw
              }
              animation3Kicker
              animation3Heading
              animation3 {
                raw
              }
              subheading {
                raw
                references {
                  ... on ContentfulComponentAlternateQuoteBlock {
                    contentful_id
                    __typename
                    id
                    type
                    internal {
                      type
                    }
                    quote {
                      quote
                    }
                    author {
                      role
                      headshot {
                        gatsbyImageData
                        file {
                          url
                        }
                        title
                        description
                      }
                      fullName
                      company {
                        name
                        logo {
                          gatsbyImageData
                          file {
                            url
                          }
                          title
                          description
                        }
                      }
                    }
                  }
                }
              }
              subheadingBullet
              kicker
              heading
              headingSize
              callsToAction {
                contentful_id
                id
                internalLink
                label
                type
                link
                variant
              }
              featuredImage {
                gatsbyImageData
                file {
                  url
                  contentType
                }
                title
                description
              }
              videoUrl
            }
            ... on ContentfulComponentFaqAccordion {
              __typename
              contentful_id
              subhead
              internalName
              heading
              noTopPadding
              noBottomPadding
              faQs {
                answer {
                  raw
                }
                question
              }
            }
            ... on ContentfulComponentVideoSection {
              __typename
              contentful_id
              type
              background
              kicker
              noTopPadding
              subheadingText {
                subheadingText
              }
              videoHeading
              internalName
              javascriptSnippet {
                javascriptSnippet
              }
              htmlSnippet {
                htmlSnippet
              }
              featuredVideo {
                videoUrl
                thumbnail {
                  gatsbyImageData
                  url
                }
                autoplay
                videoFile {
                  title
                  description
                  file {
                    url
                  }
                }
              }
            }
            ... on ContentfulComponentTestimonialSection {
              __typename
              contentful_id
              heading
              noTopPadding
              noBottomPadding
              subheading {
                subheading
              }
              type
              internalName
              testimonials {
                quote {
                  quote
                }
                logo {
                  gatsbyImageData
                  file {
                    url
                  }
                  title
                  description
                }
                author {
                  fullName
                  role
                }
              }
            }
            ... on ContentfulComponentIconBlock {
              __typename
              contentful_id
              internalName
              heading
              kicker
              type
              background
              noTopPadding
              noBottomPadding
              unrestrictImageHeight
              backgroundImage {
                id
                primaryImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                title
                description
              }
              subheading {
                subheading
              }
              callsToAction {
                contentful_id
                id
                internalLink
                label
                type
                link
                variant
              }
              icons {
                id
                alignCaption
                caption {
                  raw
                }
                description
                internalLink
                internalName
                primaryImage {
                  gatsbyImageData
                  file {
                    url
                    contentType
                  }
                  title
                  description
                }
                title
                url
                featureGridTitle
                featureGridDescription
              }
              featuredPersona {
                ... on ContentfulComponentGeneric {
                  id
                  align
                  background
                  callsToAction {
                    variant
                    type
                    link
                    label
                    id
                    internalName
                    internalLink
                    contentful_id
                  }
                  kicker
                  heading
                  headingSize
                  subheading {
                    raw
                  }
                  bodyCopy {
                    raw
                  }
                  featuredImage {
                    gatsbyImageData
                    file {
                      url
                    }
                    title
                    description
                    contentful_id
                  }
                }
              }
            }
            ... on ContentfulComponentCarousel {
              __typename
              id
              type
              background
              backgroundImage {
                primaryImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                title
                description
              }
              subheading {
                subheading
              }
              subheadingTest {
                raw
                references {
                  internalLink
                  link
                  label
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        type
                        id
                      }
                    }
                  }
                }
              }
              kicker
              heading
              headingSize
              autoplay
              noTopPadding
              noBottomPadding
              carouselItems {
                ... on ContentfulComponentGeneric {
                  id
                  align
                  background
                  callsToAction {
                    variant
                    type
                    link
                    label
                    id
                    internalName
                    internalLink
                    contentful_id
                  }
                  heading
                  headingSize
                  subheading {
                    raw
                  }
                  icon {
                    gatsbyImageData
                    file {
                      url
                    }
                    title
                    description
                    contentful_id
                  }
                  featuredImage {
                    gatsbyImageData
                    file {
                      url
                    }
                    title
                    description
                    contentful_id
                  }
                }
              }
            }
            ... on ContentfulComponentCardDeck {
              __typename
              id
              internalName
              type
              cardType
              backgroundColor
              kicker
              slide2Kicker
              headingSize
              heading
              slide2Heading
              noTopPadding
              noBottomPadding
              subheading {
                subheading
              }
              slide2Subheading {
                slide2Subheading
              }
              post {
                ... on ContentfulResource {
                  __typename
                  id
                  title
                  alternateTitle
                  description {
                    description
                  }
                  category {
                    title
                    id
                    backgroundColor
                    fontColor
                  }
                  externalUrl
                  slug
                  featuredImage {
                    gatsbyImageData
                    title
                    url
                    description
                  }
                  infographicImage {
                    gatsbyImageData
                    file {
                      fileName
                      url
                    }
                    title
                    description
                  }
                }
                ... on ContentfulComponentGeneric {
                  id
                  align
                  background
                  callsToAction {
                    variant
                    type
                    link
                    label
                    id
                    internalName
                    internalLink
                    contentful_id
                  }
                  kicker
                  heading
                  headingSize
                  bodyCopy {
                    raw
                  }
                  featuredImage {
                    gatsbyImageData
                    file {
                      url
                    }
                    title
                    description
                    contentful_id
                  }
                }
                ... on ContentfulBlogPost {
                  __typename
                  id
                  title
                  alternateTitle
                  publishDate
                  slug
                  body {
                    raw
                  }
                  description {
                    description
                  }
                  category {
                    backgroundColor
                    fontColor
                    title
                    id
                  }
                  author {
                    fullName
                    headshot {
                      gatsbyImageData
                      file {
                        url
                      }
                      title
                      description
                    }
                  }
                  featuredImage {
                    gatsbyImageData
                    url
                    title
                    description
                  }
                }
                ... on ContentfulKnowledgeCenterPost {
                  __typename
                  id
                  title
                  alternateTitle
                  slug
                  description {
                    description
                  }
                  image {
                    gatsbyImageData
                    url
                    title
                    description
                  }
                  category {
                    title
                    id
                  }
                  body {
                    references {
                      ... on ContentfulComponentImage {
                        id
                        internalLink
                        url
                        primaryImage {
                          gatsbyImageData
                          file {
                            url
                            details {
                              image {
                                height
                                width
                              }
                            }
                          }
                          id
                          title
                          description
                        }
                      }
                    }
                  }
                }
                ... on ContentfulNewsroomPost {
                  __typename
                  id
                  alternateTitle
                  title
                  externalTitle
                  publishDate
                  slug
                  description {
                    description
                  }
                  category {
                    title
                    id
                  }
                  featuredImage {
                    gatsbyImageData
                    url
                    title
                    description
                  }
                }
                ... on ContentfulComponentTile {
                  __typename
                  internalName
                  category {
                    title
                    id
                  }
                  heading
                  alternateHeading
                  headingSize
                  subheading
                  description {
                    description
                  }
                  ctaLink
                  ctaInternalLink
                  icon {
                    gatsbyImageData
                    file {
                      url
                    }
                    title
                    description
                  }
                }
              }
              slide2Post {
                ... on ContentfulResource {
                  __typename
                  id
                  title
                  alternateTitle
                  description {
                    description
                  }
                  category {
                    title
                    id
                  }
                  externalUrl
                  slug
                  featuredImage {
                    gatsbyImageData
                    title
                    url
                    title
                    description
                  }
                  infographicImage {
                    gatsbyImageData
                    file {
                      fileName
                      url
                    }
                    title
                    description
                  }
                }
                ... on ContentfulBlogPost {
                  __typename
                  id
                  title
                  alternateTitle
                  publishDate
                  slug
                  body {
                    raw
                  }
                  description {
                    description
                  }
                  category {
                    backgroundColor
                    title
                    id
                  }
                  author {
                    fullName
                    headshot {
                      gatsbyImageData
                      file {
                        url
                      }
                      title
                      description
                    }
                  }
                  featuredImage {
                    gatsbyImageData
                    url
                    title
                    description
                  }
                }
                ... on ContentfulKnowledgeCenterPost {
                  __typename
                  id
                  title
                  alternateTitle
                  slug
                  description {
                    description
                  }
                  image {
                    gatsbyImageData
                    url
                    title
                    description
                  }
                  category {
                    title
                    id
                  }
                  body {
                    references {
                      ... on ContentfulComponentImage {
                        id
                        internalLink
                        url
                        primaryImage {
                          gatsbyImageData
                          file {
                            url
                            details {
                              image {
                                height
                                width
                              }
                            }
                          }
                          id
                          title
                          description
                        }
                      }
                    }
                  }
                }
                ... on ContentfulNewsroomPost {
                  __typename
                  id
                  alternateTitle
                  title
                  externalTitle
                  publishDate
                  slug
                  description {
                    description
                  }
                  category {
                    title
                    id
                  }
                  featuredImage {
                    gatsbyImageData
                    url
                    title
                    description
                  }
                }
                ... on ContentfulComponentTile {
                  __typename
                  internalName
                  category {
                    title
                    id
                  }
                  heading
                  alternateHeading
                  headingSize
                  subheading
                  description {
                    description
                  }
                  ctaLink
                  ctaInternalLink
                  icon {
                    gatsbyImageData
                    file {
                      url
                    }
                    title
                    description
                  }
                }
              }
            }
            ... on ContentfulComponentForm {
              __typename
              id
              type
              anchorLinkId
              background
              marketoFormId
              javascriptSnippet {
                javascriptSnippet
              }
            }
            ... on ContentfulComponentTileSection {
              __typename
              id
              internalName
              kicker
              heading
              headingSize
              noTopPadding
              noBottomPadding
              pagination
              desktopPageSize
              tabletPageSize
              mobilePageSize
              tileType
              subheading {
                raw
              }
              tiles {
                id
                internalName
                kicker
                heading
                headingSize
                subheading
                description {
                  description
                }
                category {
                  title
                }
                integrations {
                  title
                }
                useCases {
                  title
                }
                userGroups {
                  title
                }
                ctaLabel
                ctaLink
                ctaVariant
                ctaInternalLink
                videoLink
                videoCaption {
                  raw
                }
                icon {
                  gatsbyImageData
                  file {
                    url
                  }
                  title
                  description
                }
              }
            }
            ... on ContentfulComponentComparisonTables {
              __typename
              heading
              noTopPadding
              noBottomPadding
              subheading {
                raw
              }
              columnHeaders {
                heading
                subheading {
                  subheading
                }
                icons {
                  primaryImage {
                    gatsbyImageData
                    title
                    description
                  }
                }
                callsToAction {
                  label
                  link
                  internalLink
                  variant
                }
              }
              tables {
                id
                tableTitle
                tableColumns {
                  id
                  tableItems {
                    id
                    content
                    htmlContent {
                      raw
                    }
                    showCheck
                    showEmpty
                  }
                }
              }
            }
            ... on ContentfulComponentUseCase {
              __typename
              id
              headingKicker
              heading
              noTopPadding
              noBottomPadding
              subheading {
                subheading
              }
              callToAction {
                label
                link
              }
              columns {
                id
                heading
                items {
                  id
                  heading
                  subheading {
                    raw
                  }
                  tooltip {
                    tooltip
                  }
                  icon {
                    url
                    title
                  }
                }
              }
            }
            ... on ContentfulComponentPricingTable {
              __typename
              id
              internalName
              kicker
              heading
              subheading {
                raw
              }
              priceItems {
                id
                heading
                price
                priceCicle
                subheading
                callsToAction {
                  variant
                  type
                  link
                  label
                  id
                  internalName
                  internalLink
                  contentful_id
                }
                bodyCopy {
                  raw
                }
                bodyCopy2 {
                  raw
                }
              }
              itemTables {
                id
                tableTitle
                tableColumns {
                  id
                  tableItems {
                    id
                    content
                    htmlContent {
                      raw
                    }
                    showCheck
                    showEmpty
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
